@import "../../../../../themes/generated/variables.base.scss";

.divContent {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  padding: 10px;
}

.collapsibleContentHorizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0;
}

.collapsibleContentVertical {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.collapsibleContent > * {
  flex: 1; /* Each child element takes up equal space */
  margin-right: 10px; /* Add some space between checkboxes */
}

.collapsibleContent > *:last-child {
  margin-right: 0; /* Remove margin from the last checkbox */
}

.column1 {
  float: left;
  width: 20%;
  padding: 0.5rem;
}

.column2 {
  float: left;
  width: 80%;
  padding: 0.5rem;
}

@media only screen and (max-width: 1200px) {
  .column1 {
    width: 25%;
  }
  .column2 {
    width: 75%;
  }
}

@media only screen and (max-width: 992px) {
  .column1 {
    width: 35%;
  }
  .column2 {
    width: 65%;
  }
}

@media only screen and (max-width: 768px) {
  .column1 {
    width: 45%;
  }
  .column2 {
    width: 55%;
  }
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.halfColumn {
  columns: 2 auto;
}

.titleLight {
  font-weight: bold;
  color: $base-accent;
}

.titleDark {
  font-weight: bold;
  color: $base-text-color;
}

.noMargin {
  margin: -20px 0px 0px 0px;
}

.gaugeDetailsCol1 {
  float: left;
  width: 40%;
  text-align: end;
  margin-top: 10px;
}

.gaugeDetailsCol2 {
  float: left;
  width: 60%;
  text-align: start;
}

.arrowIcon {
  margin-top: 5px;
}

.seperator {
  border-bottom: 1px solid #d3d3d3;
}

.afterValueCol {
  float: left;
  width: 50%;
  text-align: center;
}

.beforeValueCol {
  float: left;
  width: 50%;
  text-align: center;
}

.dateOptions {
  width: 100%;
}

.dashboardHeaderTitle {
  display: inline-block;
  width: 93%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
