.details-div {
  display: flex;
  height: calc(100vh - 420px); /* Keeps the height */
}

.cols {
  flex: 1;
  padding: 0.5rem;
  overflow-y: auto;
  max-height: 100%;
}

.details-col {
  height: calc(100vh - 440px);
  padding: 0.25rem;
  width: 50%;
  margin: 0.5rem 0.5rem 0 0 !important;
}

.bordered-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
  margin-bottom: 0.1rem;
}

.bordered-table th,
.bordered-table td {
  border: 1px solid black;
  padding: 0.5rem;
  text-align: center;
  white-space: nowrap;
  width: 33.33% !important;
  border-color: #888888;
  height: 2.6rem;
}

.bordered-table th {
  padding: 0.2rem;
}

.bordered-table td {
  padding: 0;
}

.bordered-table input {
  width: 4rem;
  text-align: center;
  border-color: black;
  border-radius: 4px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield; /* Standard property for cross-browser compatibility */
    -moz-appearance: textfield; /* Firefox-specific property */
  }
}
