@import "./themes/generated/variables.base.scss";

.app {
  display: flex;
  width: 100%;

  /* Default styles for larger screens */
  background-color: darken($base-bg, 5);
  height: 100vh;
}

/* Media query for screens narrower than 1920px */
@media (max-width: 1919px) {
  .app {
    height: calc(100vh);
    scrollbar-width: 0;
    overflow-y: hidden;
  }
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding-top: 20px;
  padding-bottom: 20px;

  .screen-large & {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.dx-icon-menu::before {
  color: $base-text-color;
}

.dx-tab-text {
  text-transform: none !important;
}

.dx-datebox-calendar .dx-dropdowneditor-icon::before {
  content: "\f026" !important;
}

.dx-datebox-time .dx-dropdowneditor-icon::before {
  content: "\f01d" !important;
}

.dx-item-content.dx-treeview-item-content {
  .fa-house:before,
  .fa-chart-simple:before,
  .fa-map-location-dot:before,
  .fa-signs-post:before,
  .fa-screwdriver-wrench:before,
  .fa-car-burst:before,
  .fa-traffic-light:before,
  .fa-cubes:before,
  .fa-phoenix-squadron:before,
  .fa-sliders:before,
  .fa-file-lines:before,
  .fa-database:before,
  .fa-house-medical:before,
  .fa-users:before,
  .fa-chart-line:before,
  .fa-book:before {
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.fa-chevron-down:before {
  font-size: 16px;
}

.fa-chevron-up:before {
  font-size: 16px;
}

.dx-dropdowneditor-icon {
  font-size: 32px;
}

.dx-icon-chevronnext:before {
  font-size: 32px;
}

.dx-icon-chevronprev:before {
  font-size: 32px;
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
  opacity: 0.75;
}

.dx-texteditor.dx-editor-filled {
  background-color: #f7f7f7;
}

.dx-texteditor.dx-editor-filled.dx-state-disabled,
.dx-texteditor.dx-editor-filled.dx-state-readonly,
.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover {
  background-color: #d4d1d1ab;
}

.dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover
  .dx-texteditor-input {
  color: #424242;
}

.dx-texteditor-label {
  color: #424242;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay-flipped.dx-popup-inherit-height {
  width: auto !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-popup-inherit-height {
  width: auto !important;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
    .dx-row-removed
  ):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}

.timeMarkerContainer .dx-texteditor.dx-editor-filled::after {
  border: none !important;
}

#gridContainer {
  max-height: 72vh;
}

#gridContainer tr {
  white-space: nowrap;
  overflow: hidden;
}

#FileChooserDataGrid tr {
  white-space: nowrap;
  overflow: hidden;
}

#FileChooserDataGrid .fa-circle-plus:before {
  color: #424242;
  font-size: 20px;
}

#FileChooserDataGrid
  .dx-editor-filled
  .dx-texteditor-buttons-container:last-child
  > .dx-dropdowneditor-button:last-child {
  width: 100px !important;
  background-color: #ffffff;
}

.dx-overlay-content.dx-toast-warning.dx-toast-content {
  color: #424242;
}

.dateOptions {
  background-color: #ffffff;
}

.dateOptions .dx-button-content {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.dateOptions.dx-state-hover {
  background-color: #f0b70d;
}

.dateOptions .dx-icon {
  height: 30px !important;
  width: 30px !important;
  margin-top: -4px;
}

.dateOptions .dx-button-text {
  text-transform: none;
  margin-left: -0.5rem;
}

.screen-x-small,
.screen-small,
.screen-medium,
.screen-large,
.screen-x-large {
  background-color: white !important;
}

.dx-button-content {
  z-index: 3 !important;
}

.gisMapSummary {
  .esri-expand__content.esri-expand__content--expanded {
    width: 12rem !important;
    right: 9rem !important;
  }

  .esri-popup__main-container.esri-widget.esri-popup--shadow {
    right: 22rem !important;
  }

  i.dx-icon.fa-solid.fa-chevron-right,
  i.dx-icon.fa-solid.fa-chevron-left {
    display: flex;
    justify-content: center;

    &::before {
      font-size: 19px;
    }
  }
}

.collapsible-card i.dx-icon.fa-solid.fa-chevron-up,
.collapsible-card i.dx-icon.fa-solid.fa-chevron-down {
  display: flex;
  justify-content: center;
}

.gisMapSummary
  .dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td {
  font-size: 13px;
}

h2.esri-widget__heading.esri-popup__header-title {
  font-size: 14px;
  font-weight: bolder;
  padding: 0;
  margin: 0.7rem 0 0 0.5rem;
}

.esri-popup__content {
  margin: 0.4rem 0 !important;
}

.esri-popup__main-container.esri-widget.esri-popup--is-collapsible {
  width: 14rem !important;
  max-height: 16rem !important;
}

.esri-popup__footer {
  padding: 0 !important;
}

th.esri-feature-fields__field-header,
td.esri-feature-fields__field-data {
  font-size: 11px !important;
}

.esri-popup__navigation {
  margin-top: 6px !important;
}

.TrafficOperation_Data .fa-arrow-up-right-from-square:before,
.TrafficOperation_Filter .fa-search:before,
.TrafficOperation_Filter .fa-filter-circle-xmark:before,
.TrafficOperation_Filter .fa-filter:before {
  font-size: 18px !important;
}

.esri-icon-minus::before {
  content: "\e67c" !important;
  font-size: 19px !important;
}

.esri-icon-plus::before {
  content: "\e67a" !important;
  font-size: 19px !important;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
  opacity: 0 !important;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  max-height: 250px !important;
}

.dx-layout-manager .dx-field-item:not(.dx-last-col),
.dx-layout-manager .dx-field-item:not(.dx-first-col) {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;
}

#aadtTableCalculationSettingsCollapse {
  .dx-layout-manager .dx-field-item:not(.dx-last-col),
  .dx-layout-manager .dx-field-item:not(.dx-first-col) {
    padding: 0.75rem 0.75rem 0 0.75rem !important;
    margin-bottom: -0.5rem;
  }
}

.popupFields .dx-layout-manager .dx-field-item:not(.dx-last-col),
.popupFields .dx-layout-manager .dx-field-item:not(.dx-first-col) {
  padding: 0.25rem 1rem 0.25rem 1rem !important;
}

.TrafficOperation_Filter
  .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(
    .dx-first-row
  ):not(.dx-label-v-align) {
  padding-top: 0 !important;
}

.leftColumn .dx-widget ::before,
.rightColumn .dx-widget ::before {
  font-size: 24px;
}

.Overview_Report i.dx-icon.fa-solid.fa-calendar::before {
  font-size: 24px;
}

.TrafficOperation_Filter
  .dx-first-row.dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
  padding: 0 !important;
}

.TrafficOperation_Filter .dx-item .dx-radiobutton {
  margin: 0.5rem 0 0 0 !important;
}

.dashboard_data_grid
  .dx-datagrid-action.dx-cell-focus-disabled.dx-datagrid-drag-action {
  padding: 0.25rem !important;
}

.dashboard_data_grid td {
  padding: 0.25rem !important;
}

.dx-item-content.dx-menu-item-content {
  display: flex;
  margin-right: 2.5rem;
  align-items: center;
}

.dx-item.dx-menu-item.dx-menu-item-has-icon.dx-menu-item-has-submenu {
  :first-child {
    margin-right: 0;
  }
}

.reportSelectButton {
  .dx-button-has-text {
    .dx-button-content {
      background-color: #f0b70d;
      &:hover {
        background-color: #ebebeb;
      }
    }
  }
}

.dx-datagrid-filter-panel-text {
  color: #424242 !important;
  font-weight: bolder;
  font-size: 13px !important;
}

.dx-datagrid-filter-panel-clear-filter {
  color: red !important;
  font-weight: bolder;
  font-size: 13px !important;
}

.dx-icon-filter::before {
  color: #424242 !important;
}

tr.dx-row.dx-header-row {
  background-color: #ffffff;
}

tr.dx-row.dx-data-row.dx-row-lines.dx-state-hover {
  background-color: #a5a5a5;
}

tr.dx-row.dx-data-row.dx-row-lines.dx-state-hover td {
  color: #ffffff !important;
}

.dx-command-select.dx-cell-focus-disabled.dx-editor-cell.dx-editor-inline-block {
  width: 2rem !important;
}

.dx-context-menu .dx-submenu {
  border-radius: 10px;
}

ul.dx-menu-items-container {
  padding: 0 !important;

  li div.dx-item.dx-menu-item.dx-menu-item-has-text {
    background-color: #ffffff;
    border: 1.5px solid #d3d3d3;
    font-weight: 500;

    &.dx-state-hover {
      background-color: #f5d36b;
    }
  }
}

.dx-item-content.dx-menu-item-content
  i.dx-icon.fa-solid.fa-up-right-from-square::before,
.dx-item-content.dx-menu-item-content
  i.dx-icon.fa-solid.fa-location-dot::before {
  font-size: 16px;
}

.geoCodingSwitchBox.dx-field-item.dx-field-item-optional.dx-flex-layout {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.userDetailsConfirmationPassField .dx-texteditor-input-container::after {
  margin-top: 1.6px !important;
}

.dx-fa-button .dx-overlay-content .dx-fa-button-icon {
  background-color: #f0b70d !important;
}

#collisionsGridContainer {
  height: calc(100vh - 20rem) !important;
  margin-top: -1.75rem !important;
}

.dashboardTab div.dx-item.dx-tab {
  padding: 5px 0 0 0;
}

td.dx-group-cell {
  color: #424242 !important;
}

.pdfViewerIcons {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  border-radius: 12px;
  transition: background-color 0.1s;

  &:hover {
    background-color: #dfdede;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

// ================= Compact View Style ================= //

.compactStyle {
  h2 {
    font-size: 24px !important;
    font-weight: 500;
    margin: 0.3rem !important;
  }

  .content-block {
    margin: 0.3rem;
  }

  .dx-card {
    padding: 0.5rem 0 0 0;
  }

  #gridContainer {
    margin-top: -1.15rem !important;
    min-height: calc(100vh - 10.5rem);
  }

  i.dx-icon.fa-solid[class*="fa-"]::before {
    font-size: 19px;
  }

  .responsive-paddings {
    padding: 0.5rem !important;
  }

  span.dx-tab-text {
    font-size: 12px;
    font-weight: 700;
  }

  div.dx-item.dx-tab {
    padding: 0;
  }

  p.detailsHeading {
    font-size: 14px;
  }

  // ========== By Page ========== //

  &.xmlImport {
    .dx-first-col.dx-last-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
      padding: 0 !important;
    }

    .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
      padding: 0 0.5rem !important;
    }

    .dx-first-row.dx-field-item.dx-col-3.dx-field-item-optional.dx-flex-layout {
      padding: 0 0.5rem !important;
    }

    .dx-first-row.dx-last-col.dx-field-item.dx-col-6.dx-field-item-optional.dx-flex-layout {
      padding: 0.5rem !important;
    }

    #filePicker {
      margin-bottom: -0.25rem;
      margin-right: 0.5rem;
    }

    #gridContainer {
      min-height: calc(100vh - 16rem);
    }
  }

  &.xmlImportLogs {
    #gridContainer {
      min-height: calc(100vh - 8rem);
    }
  }

  &.patients {
    #gridContainer {
      min-height: calc(100vh - 8rem);
    }
  }

  &.signalWarrants,
  &.allWayStopWarrant,
  &.empiricalBayes {
    .row {
      height: 1.4rem;
    }

    #rowButtons {
      margin-top: -1.5rem;
    }

    .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-last-col.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      margin: 0 !important;
      padding: 0.3rem 0 0 0 !important;
    }
  }

  &.patrolArea {
    .row {
      margin-top: -0.5rem !important;
    }

    #buttonGroup {
      height: 2.5rem !important;
    }
  }

  &.copySetting {
    .dx-card {
      margin: 0 0 0.5rem 0;
    }

    .dx-first-row.dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-last-row.dx-field-item.dx-col-9.dx-field-item-optional.dx-flex-layout {
      margin: -1rem 0 -1rem 0;
      padding: -1rem 0 -1rem 0;
    }

    #copyButton {
      margin-top: -0.5rem !important;
    }
  }

  &.infrastructure-loadData {
    .stepper {
      margin-top: 0;
      margin-bottom: -4rem;
    }

    #gridContainer {
      margin-top: -2rem !important;
      min-height: calc(100vh - 20rem);
    }
  }

  &.trafficStudy-importData {
    .stepper {
      margin-top: 0;
      margin-bottom: -3rem;
    }

    .steps {
      margin-top: -1rem;
    }

    .dx-first-col.dx-last-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
      margin-top: -2rem;
    }

    #gridContainer {
      min-height: calc(100vh - 25rem) !important;
    }

    #gridContainer2 {
      min-height: calc(100vh - 20rem) !important;
    }

    #button3 {
      margin-top: 1rem;
    }
  }

  &.tesFiled {
    #gridContainer {
      min-height: calc(100vh - 8rem) !important;
    }
  }

  &.intersectionTesFiled {
    #gridContainer {
      min-height: calc(100vh - 8rem) !important;
    }
  }

  &.roadSegmentTesFiled {
    #gridContainer {
      min-height: calc(100vh - 8rem) !important;
    }
  }

  &.intersectionDetails,
  &.roadSegmentDetails {
    .dx-card {
      padding: 0;
    }

    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem !important;
    }

    .row {
      margin-top: 0 !important;
      margin-bottom: -1.75rem !important;
    }
  }

  &.intersectionDetails-general,
  &.intersectionDetails-details,
  &.intersectionDetails-approachDetails {
    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem !important;
    }
  }

  &.intersectionDetails-operation {
    margin-top: 1rem;
  }

  &.intersectionDetails-collisions {
    margin-top: 1rem;

    #gridContainer {
      margin-top: 2rem !important;
      min-height: calc(100vh - 20rem) !important;
    }
  }

  &.intersectionDetails-trafficStudies {
    margin-top: 1rem;

    #gridContainer {
      min-height: calc(100vh - 18rem) !important;
    }
  }

  &.intersectionDetails-signs {
    margin-top: 1rem;

    #gridContainer {
      min-height: calc(100vh - 18rem) !important;
    }
  }

  &.intersectionDetails-supports {
    margin-top: 1rem;

    #gridContainer {
      margin-top: 2rem !important;
      min-height: calc(100vh - 20rem) !important;
    }
  }

  &.roadSegmentDetails-collisions,
  &.roadSegmentDetails-trafficStudies,
  &.roadSegmentDetails-support {
    margin-top: 1rem;

    #gridContainer {
      margin-top: 2rem !important;
      min-height: calc(100vh - 20rem) !important;
    }
  }

  &.roadSegmentDetails-signs {
    margin-top: 1rem;

    #gridContainer {
      margin-top: 2rem !important;
      min-height: calc(100vh - 18rem) !important;
    }
  }

  &.siteListDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.siteListDetails-share {
    #shareGrids {
      margin-top: 0rem !important;
      height: calc(100vh - 22rem) !important;
    }
  }

  &.collisionDetails {
    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem;
    }
  }

  &.collisionDetails-roads,
  &.collisionDetails-vehicles,
  &.collisionDetails-drivers,
  &.collisionDetails-passengers,
  &.collisionDetails-pedestrians,
  &.collisionDetails-people {
    #gridContainer {
      margin-top: -1rem !important;
      min-height: calc(100vh - 20rem) !important;
    }
  }

  &.collision-attachments {
    h5 {
      font-size: 16px !important;
      font-weight: 500;
      margin-top: 2.5rem;
      margin-bottom: -0.5rem;
    }

    p[id*="previousFile"],
    p[id*="recentFile"] {
      font-size: 12px;
      font-weight: bold;
    }
  }

  &.collisionDetails-changeLogs,
  &.collisionDetails-validations {
    #gridContainer {
      margin-top: -1rem !important;
      min-height: calc(100vh - 17rem) !important;
    }
  }

  &.xmlImportSettingDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.xmlImportSettingDetails-details {
    .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-last-col.dx-field-item.dx-col-3.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.5rem 0.1rem 0.1rem 0.1rem !important;
    }

    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
    .dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-3.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem !important;
    }
  }

  &.xmlImportSettingDetails-fields {
    #fieldGridContainer {
      margin-top: 0rem !important;
      height: calc(100vh - 20rem) !important;
    }
  }

  &.fieldDataSourceDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.fieldDataSourceDetails-details {
    .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.5rem 0.1rem 0.1rem 0.1rem !important;
    }

    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem !important;
    }
  }

  &.fieldDataSourceDetails-columnMap {
    #columnMapGridContainer {
      margin-top: 0rem !important;
      min-height: calc(100vh - 16rem) !important;
    }
  }

  &.geoCodeGroupDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.geoCodeGroupDetails-collisions {
    #collisionsGridContainer {
      margin-top: -0.5rem !important;
      height: calc(100vh - 20rem) !important;
    }
  }

  &.xmlImportLogDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.xmlImportLogDetails-logs {
    #logsGridContainer {
      margin-top: -0.5rem !important;
      height: calc(100vh - 12rem) !important;
    }
  }

  &.hospitalizationDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.hospitalizationDetails-patients {
    #patientsGridContainer {
      margin-top: -0.5rem !important;
      height: calc(100vh - 19rem) !important;
    }
  }

  &.aadtDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.aadtDetails-aadt {
    .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.5rem 0.1rem 0.1rem 0.1rem !important;
    }

    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
    .dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem !important;
    }
  }

  &.aadtDetails-studies {
    #studiesGridContainer {
      margin-top: -0.5rem !important;
      height: calc(100vh - 16rem) !important;
    }
  }

  &.studyDetails {
    .row {
      margin-top: 0 !important;
      margin-bottom: -1rem !important;
    }

    .dx-card {
      padding: 0;
    }
  }

  &.infrastructureLocationPicker {
    #iconsContainer {
      margin-bottom: -1rem;
    }

    #lati,
    #long {
      margin: 0rem -0.2rem 0.2rem -0.2rem !important;
      padding: 0;
    }

    #xCord,
    #yCord {
      margin: 0.2rem -0.2rem !important;
      padding: 0;
    }
  }

  &.studyDetails-details {
    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
      padding: 0.1rem !important;
      margin: 0.1rem 0.1rem -1.25rem 0.1rem !important;
    }

    #categoryTagBox {
      margin-bottom: 1.3rem !important;
    }
  }

  &.studyDetails-workOrder {
    margin-top: 0.5rem;

    .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-last-col.dx-field-item.dx-col-12.dx-field-item-optional.dx-flex-layout,
    .dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-last-row.dx-field-item.dx-col-12.dx-field-item-optional.dx-flex-layout,
    .dx-first-row.dx-field-item.dx-col-11.dx-field-item-optional.dx-flex-layout {
      margin: -0.75rem -0.75rem -0.75rem -0.75rem !important;
      padding: -0.75rem -1rem !important;
    }

    .dx-first-row.dx-field-item.dx-col-11.dx-field-item-optional.dx-flex-layout {
      margin: -0.75rem 0.1rem -0.75rem 0.5rem !important;
    }
  }

  &.studyDetails-reportingPeriod {
    #iconsCard {
      margin-top: 0.5rem !important;
      padding-top: 0.2rem !important;
    }

    #reportButtonContainer {
      margin-top: -2rem !important;
    }

    #reportingPeriodGridContainer {
      margin-top: -1.5rem !important;
      height: calc(100vh - 23rem) !important;
    }
  }

  &.studyDetails-resultSummary {
    #iconsCard {
      margin-top: 0.5rem !important;
      margin-bottom: -0.75rem !important;
    }

    #dateBoxes {
      margin: -1.5rem 0 0 0 !important;
    }

    #applyButton {
      margin-top: -1rem !important;
    }

    #DetailsGrid {
      margin-top: 5rem !important;
      height: calc(100vh - 25rem) !important;
    }
  }

  &.studyDetails-roadSegmentAADT {
    #iconsCard {
      margin-top: 0.5rem !important;
      padding-top: 0.2rem !important;
    }

    #studyAADTCollapse {
      margin-top: -2rem;
      .dx-first-row.dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-last-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout {
        margin: -0.5rem 0rem -2rem -0.75rem !important;
      }
    }

    #roadSegmentGrid {
      margin-top: -2rem !important;
      height: calc(100vh - 25rem) !important;
    }
  }

  &.studyDetails-validations {
    #logsGrid,
    #importLogsGrid {
      margin-top: -1rem !important;
    }
  }

  &.studyDetails-relatedStudies {
    #relatedStudiesGrid {
      margin-top: 0.5rem !important;
      height: calc(100vh - 16rem) !important;
    }
  }

  &.studyDetails-signalWarrants {
    #iconsCard {
      margin-top: 0.5rem !important;
      padding-top: 0.2rem !important;
      margin-bottom: 1rem !important;
      height: 3rem !important;
    }

    #period {
      margin-top: -0.75rem;
      margin-bottom: -2.25rem;
    }

    #configurationsCollapse {
      margin-bottom: -1.5rem;

      .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: -0.75rem 0.25rem !important;
      }

      .dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }
    }
  }

  &.studyDetails-allWayStopWarrant {
    #iconsCard {
      margin-top: 0.5rem !important;
      padding-top: 0.2rem !important;
      margin-bottom: 1rem !important;
      height: 3rem !important;
    }
  }

  &.studyDetails-allWay-urban {
    #period {
      margin-top: -1rem !important;
      margin-bottom: -3.25rem !important;
    }

    #configurationsCollapse {
      margin-bottom: -1.5rem;
      margin-top: -3.5rem;

      .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: -0.75rem 0.25rem !important;
      }

      .dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      #crossingTrafficDelay {
        margin-top: 0.75rem;
      }

      #analysisGrid {
        margin-top: 5rem !important;
      }
    }
  }

  &.studyDetails-allWay-collectorRural {
    #period {
      margin-top: -1rem !important;
      margin-bottom: -3.25rem !important;
    }

    #configurationsCollapse {
      margin-bottom: -1.5rem;
      margin-top: -3.5rem;

      .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: -0.75rem 0.25rem !important;
      }

      .dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      #crossingTrafficDelay {
        margin-top: 0.75rem;
      }

      #analysisGrid {
        margin-top: 5rem !important;
      }
    }
  }
  &.studyDetails-allWay-local {
    #period {
      margin-top: -1rem !important;
      margin-bottom: -3.25rem !important;
    }

    #configurationsCollapse {
      margin-bottom: -1.5rem;
      margin-top: -3.5rem;

      .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: -0.75rem 0.25rem !important;
      }

      .dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: -0.75rem 0.25rem !important;
      }

      #analysisGrid {
        margin-top: 5rem !important;
      }
    }
  }

  &.studyDetails-allWay-collision {
    #period {
      margin-top: -1rem !important;
      margin-bottom: -3.25rem !important;
    }

    #configurationsCollapse {
      margin-bottom: -1.5rem;
      margin-top: -2rem;
    }

    .modalInput.dx-show-invalid-badge.dx-tagbox.dx-tagbox-only-select.dx-selectbox.dx-textbox.dx-texteditor.dx-editor-filled.dx-widget.dx-state-disabled.dx-tagbox-default-template.dx-texteditor-with-label.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
      margin-bottom: 0 !important;
    }
  }

  &.studyDetails-intersectionAADT {
    #iconsCard {
      margin-top: 0.5rem !important;
      padding-top: 0.2rem !important;
    }

    #studyAADTCollapse {
      margin-bottom: -1rem;
      margin-top: -2rem;

      .dx-first-row.dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-first-row.dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }

      .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
      .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
      .dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: -0.75rem 0.25rem !important;
      }

      .dx-first-col.dx-last-row.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout {
        padding: 0rem !important;
        margin: 0rem 0.25rem !important;
      }
    }

    #studyAADTByDayCollapse {
      margin-bottom: -1.5rem;
      margin-top: 0rem;
    }
  }

  .studyDetails-volume-resultSummary {
    #DetailsGrid {
      margin-top: 0rem !important;
      height: calc(100vh - 24rem) !important;
    }
  }

  .studyDetails-speedStatistics {
    #speedStatisticsGrid {
      margin-top: 0rem !important;
    }
  }

  .signDetails-details {
    .dx-first-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout,
    .dx-field-item.dx-col-1.dx-field-item-optional.dx-flex-layout,
    .dx-last-col.dx-field-item.dx-col-2.dx-field-item-optional.dx-flex-layout {
      padding: 0rem !important;
      margin: 0.25rem !important;
    }

    .signDetails-location {
    }
  }
}
