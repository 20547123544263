.fileBasketBadge {
  color: rgb(255, 72, 0);
  padding-top: 0.7rem;
  margin-left: -1rem;
}

// .fa-trash:before {
//   color:rgb(255, 72, 0);
// }
.dx-loadpanel-wrapper {
  z-index: 2000 !important;
}
