.bordered-table {
  border-collapse: collapse;
  width: 100%; /* Ensure table occupies all parent div */
  table-layout: auto;
}

.bordered-table th,
.bordered-table td {
  border: 1px solid black;
  padding: 0.5rem;
  text-align: center;
  white-space: nowrap;
  min-width: 5rem !important;
  border-color: #d3d3d3 !important;
}

.bordered-table input {
  width: 4rem !important;
  text-align: center;
  border-color: black;
  border-radius: 4px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield; /* Standard property for cross-browser compatibility */
    -moz-appearance: textfield; /* Firefox-specific property */
  }
}
