.header {
  text-align: center;
}

.homeImage {
  min-height: 100%;
  width: 100%;
  //  background-image: url("../../assets/img/home/home.jpg");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
