.halfColumn {
  columns: 2 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.dx-datagrid-nowrap,
.dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
  white-space: normal !important;
}

.upperSummery {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 15px;
  margin: 2rem 1rem 0rem -1rem;
  padding: 0;
}

.summary {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin: 1rem 1rem 0rem 0rem;
}

td,
th {
  width: auto !important;
}
