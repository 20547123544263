.rightColumn {
  float: right;
  margin-right: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 20px;
}

.dx-datagrid .dx-row > td {
  text-align: left !important;
}

.dx-icon-pinleft::before {
  content: "\f154" !important;
}

.dx-icon-pinright::before {
  content: "\f155" !important;
}
