@import "../../../../../../themes/generated/variables.base.scss";

.dx-popup-wrapper > .dx-overlay-content {
  // width: 50vw !important;
  overflow-y: scroll;
}

#viewDiv {
  height: calc(95vh - 10.5rem);
}

.dx-popup-flex-height .dx-popup-content {
  max-height: calc(100vh - 200px) !important;
}

iframe {
  display: none !important;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media only screen and (max-width: 1180px) {
  .mainContainer {
    flex-direction: column;
  }
}

.tools {
  width: 49.5%;
  margin: 0rem 1rem 1rem 1rem;
  border-radius: 2px;
}

@media only screen and (max-width: 1180px) {
  .tools {
    width: 93%;
  }
}

.dx-overlay-wrapper .dx-overlay-content.dx-popup-flex-height > .dx-popup-title {
  height: 4rem;
}

.conditional {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0rem 1rem;
  background-color: #b4b3b3;
  height: 3.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0rem 0.5rem;
}

// .settingOpened {
//   color:#424242;
//   font-size: 20px;
//   margin: 2rem auto 0 0;
//     &:hover {
//       cursor: pointer;
//     }
// }

// .settingClosed {
//   color:#424242;
//   font-size: 20px;
//   margin: 0 auto 0.5rem 1.25rem;
//     &:hover {
//       cursor: pointer;
//     }
// }

@media only screen and (min-width: 768px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 490px !important;
  }
}

@media only screen and (min-width: 1080px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 425px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 620px !important;
  }
}

@media only screen and (min-width: 2160px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 900px !important;
  }
}

.esri-view.full-Height {
  height: calc(100vh - 70px) !important;
}

//Export title
.esri-print__header-title {
  display: none;
}

//Export title
.esri-icon-layer-list {
  display: none !important;
}

//Adding : after
.esri-layer-list__item-title::after {
  content: ":";
}

//Layer Management li container
li.esri-layer-list__item {
  display: flex;
}

.popup-button {
  display: inline-flex;
  align-items: center;
  color: #424242; /* Set the icon color */
  padding: 8px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 4px;
  text-decoration: none;
}

.popup-button:hover {
  background-color: transparent;
  color: #f0b70d; /* Set the icon color on hover */
}

.esri-legend__message {
  display: none;
}

// .esri-feature-layer-title {
//   font-size: 14px; /* Adjust the font size to your preference */
// }
//Soroosh: Comented becuse of legend was not working
// .esri-legend__layer {
//   margin-top: 0.2rem !important;
//   width: 3rem;
//   overflow: hidden !important;

//   image {
//     transform: translateY(-0.43rem);
//   }
// }

// .esri-legend__service {
//   padding: 0;
//   height: 1.5rem;
//   overflow: hidden !important;
// }

#layersDiv {
  background-color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;
}

.esri-legend__title {
  font-size: 12px; /* Adjust font size */
  font-weight: bold; /* Adjust font weight */
  color: #333333; /* Adjust text color */
  font-family: Arial, sans-serif; /* Adjust font family */
  margin-bottom: 10px; /* Adjust spacing */
}
