.mainContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  border: 1px solid red;
}
.captureTextTitle {
  font-size: 1rem;
  color: #101010;
  margin: 0;
  padding: 0;
}
.captureText {
  font-size: 1rem;
  font-weight: bold;
  color: #101010;
  margin: 12px 0 0 24px;
  padding: 0;
}
.tableTitle {
  color: #101010;
  margin: 22px 0 4px 0;
  padding: 0;
}
.headerRow {
  display: flex;
  justify-content: space-between;
  padding: 0 180px 0 16px;
  align-items: center;
  background-color: #c3c3c3;
  border-radius: 4px 4px 0 0;
  height: 32px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 16px;
  background-color: #efeeee;
  border-radius: 4px;
  height: 28px;
  margin-top: 2px;
}
.rowContentContainer {
  width: calc(100% - 164px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rowActionContainer {
  width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableBodyContainer {
  min-height: 300px;
  max-height: 580px;
  overflow-y: auto;
  margin-bottom: 12px;
}
.loadingText {
  margin-left: 24px;
}
.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
