.eyeIcon {
  color: #424242;
  margin-right: 1rem;
  padding: 0;
  display: flex;
  margin-left: auto;
  padding-top: 1.5rem;
  width: fit-content;
  cursor: pointer;
  &:hover {
    color: #f0b70d;
  }
}

.passwordField {
  height: 3rem;
  input {
    margin-top: -1.3rem;
  }
}
