.halfColumn {
  columns: 2 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.title {
  // margin: -1rem 0rem -1rem 0rem;
  font-size: 16px;
  font-weight: 400;
}
