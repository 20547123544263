.pdfViewerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.pdfControls {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  padding: 10px;
  z-index: 1000;
  display: flex;
  // justify-content: center;
  // align-items: center;
  border-top: 1px solid #aaaaaa;
  background-color: #b6b6b6;
  width: 100%; /* Ensures that the controls stretch across the top */
}

.pdfControls button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.pdfControls div {
  display: flex;
  align-items: center;
}

.pdfControls span {
  margin: 0 15px;
}

/* PDF Content Wrapper (Scrollable area for PDF) */
.pdfContentWrapper {
  width: 100%;
  height: calc(100% - 50px); /* Adjust height for controls space */
  overflow: auto; /* Allows scrolling for the PDF content */
  margin-top: 0px; /* Push the PDF content down as much as the controls height */
  display: flex;
  justify-content: center; /* Center the PDF content horizontally */
  align-items: flex-start; /* Align the PDF content at the top */
}

/* Disable horizontal scroll when page-width mode is active */
.pageWidthMode {
  overflow-x: hidden; /* Disable horizontal scrolling */
}

/* Centering PDF in its container */
.pdfContainer {
  display: block;
  width: 100%; /* Full width of the container */
  max-width: 100%; /* Make sure it doesn't overflow */
  transform-origin: top center; /* Scale from the top */
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}
