.rightColumn {
  float: right;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.detailsHeading {
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #424242;
}

.line {
  color: #d3d3d3;
  margin-left: 10px;
  margin-right: 10px;
}

.saveButton {
  margin-top: 20px;
}
