@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    // color: $base-bg;
  }
}

.default-header .dx-toolbar-items-container {
  // background-color: $base-accent;
  background: linear-gradient(to right, #f0b70d, #faecc2);
}

.red-header .dx-toolbar-items-container {
  // background-color: $base-accent;
  background: linear-gradient(to right, #d60c1a, #eea0a0);
}

.blue-header .dx-toolbar-items-container {
  // background-color: $base-accent;
  background: linear-gradient(to right, #0c68f0, #bcbdf7);
}

.green-header .dx-toolbar-items-container {
  // background-color: $base-accent;
  background: linear-gradient(to right, #4cff00, #cce8c0);
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.logo {
  margin: 8px 20px 8px 40px;
  height: 1.65rem;
}

.client {
  margin-left: 25px;
  font-weight: bold;
  font-size: 15px;
  color: $base-text-color;
  &:hover {
    color: $base-bg;
  }
}

.fa-bars:before {
  color: $base-text-color;
}

.viewModeButton {
  height: 1.8rem !important;
  width: 10rem !important;
  background-color: black;
  border-radius: 4px;

  ::before {
    color: #f9e4a7;
  }

  i.dx-icon.fa-solid.fa-eye::before {
    padding-right: 0.8rem;
  }

  span.dx-button-text {
    color: #f9e4a7;
  }
}

i.dx-icon.fa-solid.fa-maximize.dx-list-item-icon::before,
i.dx-icon.fa-solid.fa-minimize.dx-list-item-icon::before {
  font-size: 18px;
}

i.dx-icon.fa-solid.fa-check.dx-list-item-icon::before {
  font-size: 20px;
}

.dx-item-content.dx-list-item-content {
  align-items: center !important;
  padding: 0.5rem 0 0.5rem 0.5rem !important;
  font-weight: bolder;

  &:hover {
    ::before {
      color: #f0b70d;
    }
  }
}
