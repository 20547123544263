.halfColumn {
  columns: 2 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Modal overlay styling */
.pdfModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures modal is on top */
}

.pdfModalContent {
  width: 60%;
  height: 90%;
  background: white;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
}

.pdfModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.pdfModalHeader h2 {
  margin: 0 0 0 20px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.pdfModalBody {
  flex-grow: 1;
  overflow: auto; /* Allows scrolling within the modal */
  display: flex;
  justify-content: center; /* Centers the PDF content horizontally */
  align-items: center; /* Centers the PDF content vertically */
}

.pdfModalBody .react-pdf__Page {
  display: block;
  margin: 0 auto; /* Ensures that the PDF page is centered */
}

/* Optionally, you can add styles for responsiveness */
@media (max-width: 768px) {
  .pdfModalContent {
    width: 90%;
    height: 80%;
  }
}
