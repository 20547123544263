.labels {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px;
}

.spans {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: lighter !important;
  font-size: 14px;
}

.switches {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: lighter !important;
  font-size: 14px;
}

.bolder {
  font-weight: bolder !important;
}

.div {
  margin: 5px !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 0 40px;
}

.column {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.title {
  color: #424242 !important;
  padding: 0px !important;
  margin: 20px 0px 0px !important;
  font-size: 16px;
  font-weight: bold;
}

.checkBox {
  margin: 20px 0 10px 20px;
}

.line {
  border-top: 1px solid rgb(159, 159, 159);
  width: 100%;
  margin: 10px;
  /* Specify line color and thickness */
}

.locationColumn {
  flex: 1;
  align-items: center;
}

.locationColumn:first-child {
  flex: 4;
  align-items: center;
}

.locationRow {
  display: flex;
  align-items: center;
}

.justifyRight {
  justify-content: flex-end;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .column {
    flex-basis: 50%;
  }
}

/* Media Query for even smaller screens */
@media screen and (max-width: 480px) {
  .column {
    flex-basis: 100%;
  }
}

.floatingButton {
  background-color: #f0b70d;
  position: absolute;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  top: 1rem;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.pins {
  width: 1.4rem;
  height: 1.4rem;
}

.mapIcons {
  padding: 0 0 0 0;

  img {
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}

.iconMainBox {
  display: flex;
}
