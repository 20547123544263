.halfColumn {
  columns: 2 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.calculatorIcon {
  color: #424242;
  font-size: 1.5rem;
  margin: 0.85rem 0 0 -1rem;
}

.calculatorUnlocked {
  cursor: pointer;
  &:hover {
    color: #f0b70d;
  }
}

.calculatorLocked {
  color: #9a9a9a;
}
