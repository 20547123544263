.pageTitle {
  margin-left: 3rem;
  margin-top: 0.5rem !important;
}

.fixedDiv {
  position: fixed;
  top: 4.25rem;
  right: 3.3rem;
  width: 28rem;
  height: 2.5rem;
  z-index: 1000;
  display: inline-block;
}

.rightCollapsibleCardContainer {
  margin-top: -2.25rem;
}

.leftLocationPicker {
  margin-top: -1.75rem;
}

.fields {
  margin-top: -1rem !important;
}

p.filedParagraph {
  margin-top: -1rem;
  margin-bottom: -0.25rem;
  font-weight: bolder;
}

span.fieldSpan {
  font-weight: normal;
  font-size: larger;
}

div.switchTitle {
  margin-right: 1rem;
  font-weight: bolder;
  display: inline-block;
}

span.link {
  font-weight: bolder;
}

.linkContainer {
  margin: 1rem 0 0 0.75rem;
}
