.rightColumn {
  float: right;
  margin-right: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 20px;
}

.checkIcon {
  color: #5cb85c;
  font-size: 1.75rem;
  cursor: pointer;

  &:hover {
    color: #013220;
  }
}

.centeredIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBorder {
  border: 1px solid #d3d3d3;
}

.errorButton {
  background-color: #ff0000;
  color: #ffff;
}

.centered {
  text-align: center;
}
