.container {
  display: flex;
  width: 100%;
  height: 6px;
  margin: 0;
  padding: 0;
}

.sections {
  display: inline-block;
  height: 6px;
  margin: 0;
  padding: 0;
}

.greenSection {
  background-color: #32a350;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.yellowSection {
  background-color: #fbbf03;
}

.redSection {
  background-color: #de3e32;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.relativeDiv {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: white;
  border: 2px solid blue;
  border-radius: 6px;
}

.label {
  position: absolute;
  font-size: 12px;
  // font-weight: bolder;
}

.tooltip {
  position: absolute;
  font-size: 12px;
  font-weight: bolder;
  padding: 3px 5px;
  border-radius: 10px;
}
