.collapsible-card {
  margin: 0.5rem 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0px 15px 10px;
  overflow: hidden;
  border-bottom: 1px solid #a4a4a4;
}

.header {
  height: 38.5px;
  margin: 0.5rem 0 0.75rem 0;
}

.headerTitle {
  display: inline-block;
  width: 80%;
  text-align: left;
  padding: 12px 0;
  font-size: 18px;
  color: #424242;
}

.headerTitleCompacted {
  display: inline-block;
  width: 80%;
  text-align: left;
  padding: 12px 0;
  font-size: 14px;
  font-weight: bolder;
  color: #424242;
}

.headerIcon {
  display: inline-block;
  text-align: right;
  width: 20%;
  //margin-top: 10px;
}

.content-open {
  transition: max-height 2s ease;
  overflow: hidden;
  max-height: none;
}

.condensedGrid {
  transition: max-height 2s ease;
  overflow: hidden;
  max-height: none;
  margin-top: -4.5rem;
}

.textCompact {
  transition: max-height 2s ease;
  overflow: hidden;
  max-height: none;
  margin-top: -1.5rem;
  margin-bottom: -1rem;
}

.content-close {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.8s ease;
}

.dashboard-collapsible-card {
  margin: 0.5rem 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0px 8px;
  overflow: hidden;
  border-bottom: 1px solid #a4a4a4;
}

.dashboard-header {
  display: inline-block;
  width: 93%;
  text-align: left;
  color: #424242;
}

.dashboard-headerTitle {
  display: inline-block;
  width: 93%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.dashboard-headerIcon {
  display: inline-block;
  text-align: right;
  width: 7%;
  padding-top: 3px;
  padding-bottom: 3px;
  //margin-top: 10px;
}
