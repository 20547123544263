@import "../generated/variables.base.scss";

.tes-modal-btn-add {
  background-color: $base-accent;
}

.tes-modal-btn-cancel {
  background-color: #a77676;
}

.tes-button-warning {
  background-color: #ffff00;
}

.tes-button-success {
  background-color: #008000;
}
.tes-button-error {
  background-color: #ff0000;
  color: #ffff;
}
