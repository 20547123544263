.row {
  display: flex;

  .middleColumn {
    height: calc(100vh - 56px);
    flex: 2.8;
    background-color: white;
    position: relative;

    &.fullWidth {
      flex: 1;
    }

    .rightButtonContainer {
      position: absolute;
      top: 192px;
      right: 15px;
      transform: translateY(-50%);
      z-index: 1000;
      border-radius: 0;
    }

    .leftButtonContainer {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      z-index: 1000;
      border-radius: 0;
    }

    .mapContainer {
      position: relative;
    }
  }

  .leftColumn {
    height: calc(100vh - 56px);
    width: 14rem;
    background-color: white;
    overflow-y: scroll;

    &.collapsed {
      display: none;
    }
  }

  .rightColumn {
    height: calc(100vh - 56px);
    width: 23rem;
    background-color: white;
    overflow-y: scroll;

    &.collapsed {
      display: none;
    }
  }

  .mapContainer {
    position: relative;
  }

  @keyframes colorTransition {
    0% {
      background-color: #f0b70d;
    }

    50% {
      background-color: #fff;
    }

    100% {
      background-color: #f0b70d;
    }
  }

  .leftCollapseButton {
    animation: colorTransition 2.5s linear infinite;
    background-color: #f0b70d;
    border: none;
    border-radius: 0;

    .fa-chevron-left,
    .fa-chevron-right {
      color: white;
      font-size: 16px;
    }
  }

  .collapseButtonActive {
    animation: colorTransition 2.5s linear infinite reverse;
  }

  .leftCollapseButtonPaused {
    animation-play-state: paused !important;
    background-color: #f0b70d !important;
    border: none;
    border-radius: 0;

    .fa-chevron-left,
    .fa-chevron-right {
      color: white;
      font-size: 16px;
    }
  }

  body {
    overflow: hidden;
  }
}

.page {
  height: calc(94vh - 3.5rem);
}
