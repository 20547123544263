.labels {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px;
}

.studiesTitle {
  color: #f0b70d !important;
  padding: 0px !important;
  margin: 1rem 0 -2.5rem 1rem !important;
  font-size: 14px;
  position: relative;
  z-index: 2;
}

.spans {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: lighter !important;
  font-size: 14px;
}

.location {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px;
  font-weight: bold;
}

.switches {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: lighter !important;
  font-size: 12px;
}

.bolder {
  font-weight: bolder !important;
}

.line {
  border-top: 1px solid #a3a3a3;
  margin-top: 10px;
}

.bigLabel {
  color: #f0b70d !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 16px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.column {
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
}

.title {
  color: #424242 !important;
  padding: 0px !important;
  margin: 20px 0px 0px !important;
  font-size: 16px;
  font-weight: bold;
}

.popupTitle {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px 0px 0.5rem 0px !important;
  font-size: 16px;
  font-weight: bold;
}

.checkBox {
  margin-left: -0.5rem;
}

.subRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 0 40px;
}

.clearFilterButton {
  width: 100%;
  margin-top: 6px;
}
