.halfColumn {
  columns: 2 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  text-align: left !important;
}

.dx-texteditor-input {
  text-align: left !important;
}
