$color-white: rgba(255, 255, 255);

.mainContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-weight: 600;
  font-size: 10.75px;
  box-sizing: border-box;
}

.mainColumns {
  width: 32.333%;
  background-color: white;
}

.mainBlocks {
  height: 32.333%;
  border: 0;
  display: flex;
  flex-direction: column;
}

.centerBlock {
  height: 32.333%;
  border: 0;
  display: flex;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: #ff4800;
}

.row125 {
  height: 12.5%;
  width: 100%;
  border: 0;
  display: flex;
}

.column16666 {
  width: 16.66%;
  overflow-wrap: visible;
  border: 0;
  display: flex;
}

.column2_16666 {
  width: 33.332%;
  overflow-wrap: visible;
  border: 0;
}

.column3_16666 {
  width: 50%;
  overflow-wrap: visible;
  border: 0;
}

.column4_16666 {
  width: 66.664%;
  overflow-wrap: visible;
  border: 0;
}

.column5_16666 {
  width: 83.33%;
  overflow-wrap: visible;
  border: 0;
}

.column6_16666 {
  width: 100%;
  overflow-wrap: visible;
  border: 0;
}

.arrows {
  display: flex;
  font-size: 35px;
}

.row16666 {
  height: 16.666%;
  width: 100%;
  border: 0;
  display: flex;
  justify-content: flex-start;
}

.leftPad5 {
  padding-left: 5px;
}

.row50 {
  height: 50%;
  border: 0;
  display: flex;
}

.marginTop3 {
  margin-top: 3px;
}

.marginBottom23 {
  margin-bottom: 23px;
}

.marginBottom3 {
  margin-bottom: 3px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.centering {
  justify-content: center;
  align-items: center;
  display: flex;
}

.row20 {
  height: 20%;
  border: 0;
  display: flex;
}

.row75 {
  height: 75%;
  width: 100%;
  border: 0;
  display: flex;
}

.leftPad20 {
  padding-left: 20px;
}

.leftPad10 {
  padding-left: 10px;
}

.rightPad10 {
  padding-right: 10px;
}

.row4_16666 {
  height: 66.664%;
  width: 100%;
  border: 0;
  display: flex;
}

.column40 {
  width: 40%;
  overflow-wrap: visible;
  border: 0;
}

.column60 {
  width: 60%;
  overflow-wrap: visible;
  border: 0;
}

.column20 {
  width: 20%;
  overflow-wrap: visible;
  border: 0;
  display: flex;
  justify-content: center;
}

.row3_16666 {
  height: 50%;
  width: 100%;
  border: 0;
  display: flex;
}

.row60 {
  height: 60%;
  border: 0;
  display: flex;
}

.secondColor {
  color: white;
}

.legendText {
  height: 50%;
  font-size: 12.5px;
  font-weight: 700;
}

.legendIcon {
  height: 50%;
  font-size: 18px;
  margin-top: -3px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight5 {
  margin-right: 5px;
}

.eastWestStreets {
  font-size: 12.5px;
  font-weight: 700;
}

.radioButtonTitle {
  font-size: 1rem;
  font-weight: bolder;
  color: #424242;
  margin: 0rem 1rem 0 0;
}
