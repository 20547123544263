@import "../../themes/ourStyles/ourStyle.scss";

.container {
  width: 100%;
}

.stepper {
  counter-reset: step;
}

ul.stepper {
  padding: 0;
}

.stepper li {
  list-style-type: none;
  float: left;
  //    width: 50%; //should be 100% divided by number of steps
  position: relative;
  text-align: center;
}

.stepper li:before {
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  content: counter(step);
  counter-increment: step;
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2rem;
  border: 2.4px solid #424242;
  display: block;
  text-align: center;
  margin: 0 auto 4rem auto;
  border-radius: 50%;
  background-color: white;
  z-index: 999;
}

.stepper li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #424242;
  top: 1.2rem;
  left: -50%;
}

.stepper li:first-child:after {
  content: none;
}

//active circle
.stepper li.active:before {
  border-color: $base-accent;
  background-color: $base-accent;
  color: white;
}

//line connected to active circle
.stepper li.active:after {
  background-color: $base-accent;
}

.stepper .active:before {
  border-color: $base-accent;
  background-color: $base-accent;
  color: white;
}
.stepper .active:after {
  background-color: $base-accent;
}
