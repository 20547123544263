.eyeIcon {
  color: #424242;
  margin-right: 1rem;
  padding: 0;
  display: flex;
  margin-left: auto;
  padding-top: 1.5rem;
  width: fit-content;
  cursor: pointer;
  &:hover {
    color: #f0b70d;
  }
}

.passwordField {
  height: 3rem;
  input {
    margin-top: -1.3rem;
  }
}

.keyIcon {
  margin-left: -0.75rem;
  margin-top: 1.5rem;
  cursor: pointer;
  &:hover {
    color: #f0b70d;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipText {
  visibility: hidden;
  background-color: #ffffff;
  color: #424242;
  padding: 10px;
  border: solid 1px #424242;
  font-size: 13px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  width: 140px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 18px;
  margin-left: -60px;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}
