.container {
  padding: 0.3rem 0 0.3rem 0.3rem;
  font-weight: bold;
  font-size: 12pt;
  background-color: #e1aead;
  width: "65%";
  margin-bottom: 10;
  margin-left: "2rem";
}

.main {
  display: flex;
  flex-direction: row;
}
