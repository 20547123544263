@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";
@import "../../themes/ourStyles/ourStyle.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: calc(100vh - 56px);
  width: 260px;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-treeview {
      // Long text positioning
      white-space: nowrap;

      // Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 0 0 -2.2rem !important;
        }
      }

      // Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
          // margin-left: -0.3rem !important;

          // span {
          //   margin-left: -0.3rem !important;
          // }
        }
        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: bold;
          padding-left: 2.5rem;
        }
        &[aria-level="3"] .dx-treeview-item-content {
          font-weight: bold;
          padding-left: 3.3rem;
        }
        &[aria-level="4"] .dx-treeview-item-content {
          font-weight: bold;
          padding-left: 4.1rem;
        }
      }
    }

    // Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: blue;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            color: #424242;
            background-color: #faecc2;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}

.side-navigation-menu .dx-scrollable-content {
  background-color: #424242;
  // background-image: url("../../assets/img/side3.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: top center;
  // height: 100%;
  // filter:brightness(30%);
  color: #faecc2;
}

.dx-swatch-additional .dx-treeview-toggle-item-visibility::before {
  color: #faecc2;
  margin-left: 28rem;
}

.dx-treeview-item.dx-state-hover + .dx-treeview-toggle-item-visibility::before {
  color: #424242;
}

.side-navigation-menu
  .menu-container
  .dx-treeview
  .dx-treeview-node-container
  .dx-treeview-node.dx-state-selected
  > .dx-treeview-item
  * {
  color: $base-accent;
}
