.signCol {
  display: flex;
}

.signContainer {
  width: 250px;
  display: flex;
  align-items: center;
}

.fieldsCol {
  width: 100%;
  padding: 0 1rem;
}

.fieldsRow1 {
  margin-top: 0.75rem;
}

.fieldsOtherRow {
  margin-top: -1.25rem;
}

.sign {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-left: 0.75rem;
}
