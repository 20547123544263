.halfColumn {
  columns: 2 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.dx-datagrid-nowrap,
.dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
  white-space: normal !important;
}

.tableBorder {
  border: 1px solid #d3d3d3;
}

.customeTable,
table {
  border-collapse: collapse;
  width: 100%;
  line-height: 1.25rem;
  font-size: 14px;
  font-weight: 500;

  td {
    border-collapse: collapse;
    line-height: 1.25rem;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 16px;
    width: 25%;
    // height: 33.333%;
  }
}
