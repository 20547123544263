.rightColumn {
  float: right;
  margin-right: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}
.detailsHeading {
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #424242;
}
.line {
  color: #d3d3d3;
  margin-left: 10px;
  margin-right: 10px;
}

.dx-icon-pinleft::before {
  content: "\f154" !important;
}

.dx-icon-pinright::before {
  content: "\f155" !important;
}

.row {
  margin: 0;
}
