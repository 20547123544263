.rightColumn {
  float: right;
  margin-right: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 20px;
}

.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td:not(.dx-validation-pending):not(
    .dx-datagrid-select-all
  ).dx-command-select {
  padding: 1rem;
}
