.labels {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px;
}

.spans {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: lighter !important;
  font-size: 14px;
}

.switches {
  color: #424242 !important;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: lighter !important;
  font-size: 14px;
}

.bolder {
  font-weight: bolder !important;
}
