.rightColumn {
  float: right;
  margin-right: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 20px;
}

.dx-first-row.dx-last-col.dx-field-item.dx-col-13.dx-field-item-optional.dx-flex-layout {
  width: fit-content;
}
