.divContent {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  padding: 10px;
}

.column1 {
  float: left;
  width: 50%;
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.column2 {
  float: left;
  width: 50%;
  padding: 0 0.5rem 0.5rem 0.5rem;
}

// @media only screen and (max-width: 1200px) {
//     .column1 {
//       width: 50%;
//     }
//     .column2 {
//       width: 50%;
//     }
//   }

// @media only screen and (max-width: 992px) {
//   .column1 {
//     width: 50%;
//   }
//   .column2 {
//     width: 50%;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .column1 {
//     width: 45%;
//   }
//   .column2 {
//     width: 55%;
//   }
// }

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 768px) {
  .overviewMapSize {
    height: 490px !important;
  }
}

@media only screen and (min-width: 1080px) {
  .overviewMapSize {
    height: 425px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .overviewMapSize {
    height: 620px !important;
  }
}

@media only screen and (min-width: 2160px) {
  .overviewMapSize {
    height: 900px !important;
  }
}

.dateOptions {
  // border-radius: 50% !important;
  width: 100%;
}

.chartsContainer {
  display: flex;
  flex-wrap: wrap;
}

.chartBlock100 {
  width: 100%;
  padding: 0rem 0.5rem;
}

.chartBlock50 {
  width: 50%;
  padding: 0rem 0.5rem;
}
.chartBlock33 {
  width: 33.3333%;
  padding: 0rem 0.5rem;
}

.chartBlock66 {
  width: 66.6666%;
  padding: 0rem 0.5rem;
}
