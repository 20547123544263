.main {
  border-radius: 3rem;
  height: 80vh;
  margin: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(243, 197, 58);
  background: linear-gradient(
    90deg,
    rgba(243, 197, 58, 1) 17%,
    rgba(236, 236, 236, 1) 88%
  );
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  h1 {
    margin: 0;
    padding: 1rem;
    color: #424242;
    font-weight: bolder !important;
    font-size: 2.75rem !important;
    text-align: center;
  }
}

.danger {
  margin: 0 1rem;
  color: #424242;
  font-size: 15rem;
}

.message {
  padding: 1rem;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  color: #424242;
}
