@import "../../themes/generated/variables.base.scss";
.GridViewManagerActionIcons {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.GridViewManagerActionIconsFirst {
  padding-right: 0.75rem !important;
}
.gridTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 6px;
  padding: 0 1rem;
}
.gridSettingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding: 0 16px;
}
.horizontalSwitchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.horizontalSwitch {
  margin-left: 12px;
}
.resetBTNsContainer {
  display: flex;
}
.resetBTNs {
  margin-left: 12px;
}
.leftContainer {
  display: flex;
  align-items: center;
}
.activeChip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7ccec7;
  border-radius: 8px;
  color: #042a27;
  padding: 0 8px;
  margin-left: 12px;
  font-size: 0.8rem;
  height: 24px;
}
.nonActiveChip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b6b6b6;
  border-radius: 8px;
  color: #ffffff;
  padding: 0 8px;
  margin-left: 12px;
  font-size: 0.8rem;
  height: 24px;
}
.isDefaultChip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c9cfd6;
  border-radius: 8px;
  color: #35383d;
  padding: 0 8px;
  margin-left: 12px;
  font-size: 0.8rem;
  height: 24px;
  width: fit-content;
}
.setAsDefaultCheckbox {
  margin-left: 12px;
}
.shareChip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #add6ff;
  border-radius: 8px;
  color: #143d66;
  padding: 0 8px;
  margin-left: 12px;
  font-size: 0.8rem;
  height: 24px;
  width: fit-content;
}
.privateChip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c9cfd6;
  border-radius: 8px;
  color: #35383d;
  padding: 0 8px;
  margin-left: 12px;
  font-size: 0.8rem;
  height: 24px;
  width: fit-content;
}
