.iconsContainer {
  padding: 0.5rem 0 0.5rem 0.5rem;

  img {
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}

.main {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  justify-content: left;
}
