.pic {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  border: #f0b70d 2.5px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #f0b70d;
}

.banner {
  display: flex;
  height: 13rem;
  background-image: url("../../assets/img/profile/banner.jpg");
  background-size: cover;
  align-items: center;
  padding-left: 5rem;
  position: relative;
  z-index: 0;
}

.info {
  color: #f0b70d;
  padding-left: 1.5rem;
  position: relative;
  z-index: 1;
}

.user {
  font-size: 22px;
  margin-bottom: 0.4rem;
}

.email {
  font-size: 17px;
}
