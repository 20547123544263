@import "../../themes/generated/variables.base.scss";

.user-info {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: flex-end;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 10px;

    .user-image {
      width: 100%;
      height: 100%;
      background: url("../../assets/img/profile/circle-user-solid.svg");
    }
  }

  .user-name {
    font-size: 14px;
    color: $base-text-color;
    margin-left: 10px;
  }
}

.user-panel {
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }

  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  .dx-menu-item
    .dx-menu-item-content
    .dx-menu-item-popout-container
    .dx-menu-item-popout::before {
    margin-left: 0 !important;
  }

  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }

  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }

  .dx-menu-item .dx-menu-item-content {
    padding: 3px 15px 4px;
  }

  &.dx-overlay-content {
    width: 9rem !important;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}

// Custom styles for the language sub-menu
.dx-submenu .dx-menu-item {
  position: relative;

  .dx-icon {
    position: absolute;
    right: 10px;
    font-size: 14px;
  }
}

.dx-submenu.user-menu {
  width: 4rem;

  .dx-menu-item-content .check-icon {
    position: absolute;
    left: 2.4rem;
    top: 0.5rem;
  }
}

.fa-check ~ :not(.fa-check) {
  font-weight: bold;
}
