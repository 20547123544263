.div-content {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  padding: 10px;
}

.column1 {
  float: left;
  width: 20%;
  padding: 10px;
}
.column2 {
  float: left;
  width: 80%;
  padding: 10px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
