@import "../../../themes/generated/variables.base.scss";

.dx-popup-wrapper > .dx-overlay-content {
  // width: 50vw !important;
  overflow-y: scroll;
}

#viewDiv {
  height: calc(95vh - 10.5rem);
}

.dx-popup-flex-height .dx-popup-content {
  max-height: calc(100vh - 200px) !important;
}

iframe {
  display: none !important;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media only screen and (max-width: 1180px) {
  .mainContainer {
    flex-direction: column;
  }
}

.tools {
  width: 49.5%;
  margin: 0rem 1rem 1rem 1rem;
  border-radius: 2px;
}

@media only screen and (max-width: 1180px) {
  .tools {
    width: 93%;
  }
}

.dx-overlay-wrapper .dx-overlay-content.dx-popup-flex-height > .dx-popup-title {
  height: 4rem;
}

.conditional {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0rem 1rem;
  background-color: #b4b3b3;
  height: 3.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0rem 0.5rem;
}

@media only screen and (min-width: 768px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 490px !important;
  }
  .generalGISMapNew canvas {
    height: 490px !important;
  }
}

@media only screen and (min-width: 1080px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 425px !important;
  }
  .generalGISMapNew canvas {
    height: 425px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 620px !important;
  }
  .generalGISMapNew canvas {
    height: 620px !important;
  }
}

@media only screen and (min-width: 2160px) {
  .esri-view:not(.full-Height):not(.custom-Height) {
    height: 900px !important;
  }
  .generalGISMapNew canvas {
    height: 900px !important;
  }

  .SummaryCollapseCard canvas {
    height: 900px !important;
  }
}

//Export title
.esri-icon-layer-list {
  display: none !important;
}

//Adding : after
.esri-layer-list__item-title::after {
  content: ":";
}

//Layer Management li container
li.esri-layer-list__item {
  display: flex;
}

#layersDiv {
  background-color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;
}

.parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible !important;
}

.text_box {
  text-align: center;
  position: absolute;
  z-index: 222222;
  color: white;
  font-size: 2.5rem;
  font-weight: 100;
  padding: 0 0.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text_box_v1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 222222;
  text-align: center;
  color: white;
  font-size: 2.5rem;
  font-weight: 100;
  padding: 0 0.5rem;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
}
.mapLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: 100;
  padding: 0 0.5rem;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
}
.mapLoadingRowContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 111111;
  //text-align: right;
  color: white;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 2px 0.5rem 0 0.5rem;
  top: 11px;
  left: 50%;
  width: 100%;
  height: 1.4rem;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}

.mapLoadingRowContainer_fullHeight {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 1;
  //text-align: right;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 2px 0.5rem 0 0.5rem;
  top: 11px;
  left: 50%;
  width: 100%;
  height: 1.8rem;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}

.map_box {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  position: relative;
}

.map_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.map_box2 {
  position: sticky;
  width: 100%;
  height: 100%;
  background-color: #ccc;
}

.ToolsSquareShadow {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.esri-view.full-Height {
  height: calc(100vh - 70px);
  //height: calc(100vh - 70px) !important;
}

//Export title
.esri-print__header-title {
  display: none;
}

.popup-button {
  display: inline-flex;
  align-items: center;
  color: #424242; /* Set the icon color */
  padding: 8px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 4px;
  text-decoration: none;
}

.popup-button:hover {
  background-color: transparent;
  color: #f0b70d; /* Set the icon color on hover */
}

.esri-legend__message {
  display: none;
}
