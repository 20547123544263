.rightColumn {
  float: right;
  margin-right: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 20px;
}

.title {
  font-size: medium;
  text-align: left;
}
