@import "../../themes/generated/variables.base.scss";

.single-card {
  .dx-card {
    box-shadow: none;
    width: 330px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;
    background-color: rgba(53, 52, 52, 0.74);

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        line-height: 28px;
        font-weight: 500;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        color: $base-accent;
      }

      .description {
        margin-top: 2em;
        color: rgba(255, 255, 255);
        line-height: 18px;
      }
    }
  }
}
